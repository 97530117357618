import axios from "axios";
import { companyId } from "utils/constants";
import { api_requests } from "../../utils/rookie/ApiUrlsComponent";
import { getRole } from "../roles/RoleActions";

export const setUserDetails = (user) => ({
    type: "SET_USER_DETAILS",
    payload: user,
});

export const setPeopleSearchDetails = (users) => ({
    type: "SET_PEOPLE_SEARCH_DETAILS",
    payload: users,
});

export const setUsers = (user) => ({
    type: "SET_USERS",
    payload: user,
});

export const setFilteredUsersForSelect = (user) => ({
    type: "SET_FILTERED_USERS_FOR_SELECT",
    payload: user,
});

export const setProjectsForSelect = (projects) => ({
    type: "SET_PROJECTS_FOR_SELECT",
    payload: projects,
});

export const setTymelineIdFilteredUsersForSelect = (list) => ({
    type: "SET_TYMELINEID_FILTERED_USERS_FOR_SELECT",
    payload: list,
});

export const setNominatedCandidateDetails = (details) => ({
    type: "SET_NOMINATED_CANDIDATE_DETAILS",
    payload: details,
});

export const setUserDetail = (user) => ({
    type: "SET_USER_DETAIL",
    payload: user,
});

export const setDashboardUserDetail = (user) => ({
    type: "SET_DASHBOARD_USER_DETAIL",
    payload: user,
});

export const setAccountDetails = (user) => ({
    type: "SET_ACCOUNT_DETAILS",
    payload: user,
});

export const setError = (err) => ({
    type: "SET_ERROR_DETAILS",
    payload: err,
});

export const clearUsers = (err) => ({
    type: "CLEAR_USERS",
    payload: null,
});

export const changeRoute = (route) => ({
    type: "CHANGED_ROUTE",
    payload: route,
});

export const setProfileImage = (url) => ({
    type: "SET_PROFILE_IMAGE_URL",
    payload: url,
});

export const getUserDetails = (value) => (dispatch, getState) => {
    const { user } = getState();
    const config = {
        headers: {
            Authorization: user.currentUser.token,
        },
        params: {
            q: { list: value, [companyId]: user.currentUser[companyId] },
        },
    };

    return axios
        .get(api_requests.userList, config)
        .then((response) => {
            // console.log(response, "response");

            let usersData = response.data;
            dispatch(
                setUsers(
                    // usersData.dbusers.map((element) => ({
                    usersData.data.map((element) => ({
                        label: element.firstName,
                        value: element.id ?? "",
                    }))
                )
            );
            // console.log(JSON.stringify(usersData));
            // console.log(usersData, "userdata");
            dispatch(getRoles(usersData));
        })
        .catch((err) => {
            console.log(err);
            setError(err);
        });
};

export const getUsersListForSelectOptions = (value) => (dispatch, getState) => {
    const { user } = getState();
    const config = {
        headers: {
            Authorization: user.currentUser.token,
        },
        params: {
            q: { list: true, [companyId]: user.currentUser[companyId] },
        },
    };
    return axios
        .get(api_requests.userList, config)
        .then((response) => {
            let usersData = response.data;
            const updatedUsersList = usersData.data
                ?.filter((element) => element.tymelineId)
                ?.map((element) => ({
                    label: `${element.firstName} ${element.lastName}`,
                    value: element.tymelineId ?? "",
                }));
            dispatch(setFilteredUsersForSelect(updatedUsersList));
        })
        .catch((err) => {
            console.log(err);
            setError(err);
        });
};

export const getUsersListForGoals = (value) => (dispatch, getState) => {
    const { user } = getState();
    const config = {
        headers: {
            Authorization: user.currentUser.token,
        },
        params: {
            q: { list: true, [companyId]: user.currentUser[companyId] },
        },
    };
    return axios
        .get(api_requests.userList, config)
        .then((response) => {
            let usersData = response.data;
            const updatedUsersList = usersData.data
                // ?.filter((element) => element.tymelineId)
                ?.map((element) => ({
                    label: `${element.firstName} ${element.lastName}`,
                    value: element.accountId ?? "",
                }));
            dispatch(setFilteredUsersForSelect(updatedUsersList));
        })
        .catch((err) => {
            console.log(err);
            setError(err);
        });
};

export const getTymelineIdUsersListForSelectOptions =
    (value) => (dispatch, getState) => {
        const { user } = getState();
        const config = {
            headers: {
                Authorization: user.currentUser.token,
            },
            params: {
                q: { list: value, [companyId]: user.currentUser[companyId] },
            },
        };
        return axios
            .get(api_requests.userList, config)
            .then((response) => {
                let usersData = response.data;
                const updatedUsersList = usersData.data
                    ?.filter((item) => item.tymelineId)
                    ?.map((element) => ({
                        label: `${element.firstName} ${element.lastName}`,
                        value: element.tymelineId ?? "",
                    }));
                dispatch(setTymelineIdFilteredUsersForSelect(updatedUsersList));
            })
            .catch((err) => {
                console.log(err);
                setError(err);
            });
    };

export const getPaginatedUserDetails =
    (url, type, offset) => (dispatch, getState) => {
        const { user } = getState();

        if (type === "paginated") {
            const newConfig = {
                headers: {
                    Authorization: user.currentUser.token,
                },
                params: {
                    q: { [companyId]: user.currentUser[companyId] },
                    limit: 10,
                    offset: offset,
                },
            };
            axios
                .get(api_requests.userList, newConfig)
                .then((response) => {
                    let usersData = response.data;

                    // console.log(usersData.dbusers);
                    dispatch(getRoles(usersData));

                    // dispatch(setUsers(response.data.dbusers.map(element => ({ label: element.userprofile_metadata.email, value: element.profile_id }))));
                })
                .catch((err) => setError(err));
        } else {
            const config = {
                headers: {
                    Authorization: user.currentUser.token,
                },
            };
            axios
                .get(url, config)
                .then((response) => {
                    let usersData = response.data;

                    // console.log(usersData.dbusers);
                    dispatch(getRoles(usersData));

                    // dispatch(setUsers(response.data.dbusers.map(element => ({ label: element.userprofile_metadata.email, value: element.profile_id }))));
                })
                .catch((err) => setError(err));
        }
    };

export const getRoles = (usersData) => (dispatch, getState) => {
    const { user } = getState();
    let getCalls = [];
    // usersData.dbusers.forEach((element) => {
    usersData.data.forEach((element) => {
        const config = {
            headers: {
                Authorization: user.currentUser.token,
            },
            params: {
                q: { role_id: element.role },
            },
        };
        getCalls.push(axios.get(api_requests.roleList, config));
    });
    Promise.all(getCalls).then((responses) => {
        const final_data = responses.map((response) => {
            let roles_data = response.data;
            // for (const element of usersData.dbusers) {
            for (const element of usersData.data) {
                roles_data.roles.forEach((ele) => {
                    if (element.role === ele.role_id) {
                        element["role_title"] = ele.role_title;
                    }
                });
            }
            return usersData;
        });
        // console.log(final_data[0]);
        if (final_data[0] !== undefined) {
            dispatch(setUserDetails(usersData));
        }
    });
};

export const getIndividualUserDetails = (id, type) => (dispatch, getState) => {
    const { user } = getState();
    const config = {
        headers: {
            Authorization: user.currentUser.token,
        },
    };
    axios
        .get(api_requests.user + id, config)
        .then((response) => {
            let userData = response.data;
            if (type === "dashboard") {
                dispatch(setDashboardUserDetail(userData));
            } else {
                dispatch(setUserDetail(userData));
            }
            dispatch(getRole(userData.role));
            // if (userData.team === null) {
            //   dispatch(setUserDetail(userData));
            //   dispatch(getRole(userData.role));
            // } else {
            //   axios
            //     .get(api_requests.teamTitle + userData.team , config)
            //     .then((teamResponse) => {
            //       let team = teamResponse.data;
            //       // console.log(teamResponse);
            //       userData.title = team.title;
            //       dispatch(setUserDetail(userData));
            //       dispatch(getRole(userData.role));
            //     });
            // }
        })
        .catch((err) => setError(err));
};

export const createDBusers = (data) => (dispatch, getState) => {
    const { user } = getState();
    const config = {
        headers: {
            Authorization: user.currentUser.token,
        },
    };
    // console.log(config);
    axios
        .post(api_requests.user.slice(0, -1), data, config)
        .then((response) => {
            // console.log(response.data);
            // localStorage.setItem('oid', response.data.orientation_id)
            dispatch(changeRoute(true));
        })
        .catch((err) => {
            if (err.response !== undefined) {
                dispatch(setError(err.response.data.detail));
                setTimeout(() => {
                    dispatch(setError(null));
                }, 6000);
            }
        });
};

export const updateDBuser = (data, id) => (dispatch, getState) => {
    const { user } = getState();
    const config = {
        headers: {
            Authorization: user.currentUser.token,
        },
    };
    // console.log(config);
    axios
        .put(api_requests.user + id, data, config)
        .then((response) => {
            // console.log(response.data);
            // localStorage.setItem('oid', response.data.orientation_id)
            dispatch(changeRoute(true));
        })
        .catch((err) => {
            if (err.response !== undefined) {
                dispatch(setError(err.response.data.detail));
                setTimeout(() => {
                    dispatch(setError(null));
                }, 6000);
            }
        });
};

export const getAccountDetails = (id) => (dispatch, getState) => {
    const { user } = getState();
    const config = {
        headers: {
            Authorization: user.currentUser.token,
        },
    };
    axios
        .get(api_requests.user_account + id, config)
        .then((response) => {
            const accountDetails = response.data;
            const { courses } = accountDetails;
            let transformedCourses = courses.reduce((a, v) => {
                for (let [key] of Object.entries(v)) {
                    return { ...a, [key]: { id: key, enrolledStatus: v[key] } };
                }
                return null;
            }, {});
            accountDetails.courses = transformedCourses;
            dispatch(setAccountDetails(accountDetails));
        })
        .catch((err) => {
            if (err.response !== undefined) {
                dispatch(setError(err.response.data.detail));
                setTimeout(() => {
                    dispatch(setError(null));
                }, 6000);
            } else {
                // Todo: User feedback to communicate session expiry
                console.error(
                    "Get authenticated user failed. Re-directing to login"
                );
                // hard reload
                localStorage.clear();
                window.location.href = "/admin/login";
            }
        });
};

export const generateLink = (data, state) => {
    const config = {
        headers: {
            Authorization: state.token,
        },
    };
    // console.log(config);
    return axios
        .post(api_requests.generateLink, data, config)
        .then((response) => {
            return response;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const createNewUser = (data, state) => {
    // const config = {
    //   headers: {
    //     Authorization: state.token,
    //   },
    // };
    // console.log(config);
    return axios
        .post(api_requests.newUser, data)
        .then((response) => {
            return response;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const deleteLink = (link, state) => {
    const config = {
        headers: {
            Authorization: state.token,
        },
    };
    // console.log(config);
    return axios
        .put(api_requests.deleteLink, link, config)
        .then((response) => {
            return response;
        })
        .catch((err) => {
            console.log(err);
        });
};
