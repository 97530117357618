import React from "react";

import { Box, Typography } from "components/toolkit";
import { blue, green, grey, orange } from "@mui/material/colors";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const NEW_STATUS = "New";
const IN_PROGRESS_STATUS = "In Progress";
const COMPLETED_STATUS = "Completed";

export const StatusPill = ({ status }) => {
    const colorMap = {
        [IN_PROGRESS_STATUS]: orange[700],
        [NEW_STATUS]: blue[400],
        [COMPLETED_STATUS]: green[400],
    };
    const color = colorMap[status];
    return (
        <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            gap={0.5}
            sx={{
                py: 0.5,
                px: 1,
                height: "1.5rem",
                minWidth: "2.5rem",
                borderRadius: 2,
                border: "1px solid",
                color: `${color} !important`,
            }}>
            <Typography variant='caption' style={{ color: "inherit" }}>
                {status}
            </Typography>
            {status === COMPLETED_STATUS && <CheckCircleIcon color='success' />}
        </Box>
    );
};

export default StatusPill;
